import React, {useContext, useRef, useState} from 'react';
import {Button, Modal, Input } from 'antd';
import Rolename from '../../components/RolesName/RolesName';
import ProfileImage from '../WidgetBar/ProfileImage';
import Moment from 'moment';
import { AuthContext } from '../../App';
import EasyEdit from 'react-easy-edit';
import API from '../../utils/API';
import { Link } from 'react-router-dom';
import {  useHistory, useLocation } from 'react-router';
import Linkify from 'react-linkify';
import linkifyHtml from 'linkify-html';
import {useDropzone} from 'react-dropzone'
const { TextArea } = Input;



const Post = props => {
  const { state } = useContext(AuthContext);
  const [displayvalue, setdisplayvalue] = useState(null);
  const [displayvalueTopictitle, setdisplayvalueTopictitle] = useState(null);
  const message = useRef(props.data.message);
  const topictitle = useRef(props.topictitle);
 
  const [loading, setloading] = useState(false);
  const [visible, setvisible] = useState(false);
  const [visibletopicmodal, setvisibletopicmodal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [disableModalButtonAction, setdisableModalButtonAction] = useState(true);
  const [disableModalButtonActionLoading, setdisableModalButtonActionloading] = useState(false);
  const [deleteReasonText, setdeleteReasonText] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [visiblephotodeletemodal, setVisiblephotodeletemodal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [topicPhotos, setTopicPhotos] = useState(props?.topicphoto ? props?.topicphoto : []);
  const [visiblephotoaddmodal, setVisiblephotoaddmodal] = useState(false)
  const [files, setFiles] = useState([]);
  const [fileRejectionsError, setfileRejectionsError] = useState('');
  if(!state.isAuthenticated){
    history.push('/login?='+location.pathname+location.search);
  }
  const   DropzoneFunctional = ({ onDrop }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: {
        'image/jpeg': [],
        'images/png':[],
        'images/jpg':[],
        'images/gif':[]
      },
      maxFiles: 4
    });
    return (
      <div
        {...getRootProps()}
        style={{
          border: isDragActive ? '2px dashed #000' : '2px dashed #eee',
          padding: '20px',
          marginBottom: '20px'
        }}
      >
        {isDragActive ? (
          <p className="dropzone">Drop your image files here</p>
        ) : (
          <><p className="dropzone">Drag and drop some image files here, or click to select files</p>
          <em>(Only *.jpeg and *.png images will be accepted)</em></>
        )}
        <input {...getInputProps()} />
      </div>
    );
  };
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box',
    position: 'relative'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const imgstyle = {
    display: 'block',
    width: '100%',
    height: '100%'
  };
  const save = (value) => {
    API.post(
      `/posts/update`,
      {
        id: props.data._id,
        message: value.trim(),
        triggeremail: state.user.level,
        location: location,
      },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    )
      .then(result => {
       message.current = value;
       setdisplayvalue(value);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
  }
  const updateTitle = (value) => {
    topictitle.current = value;
    setdisplayvalueTopictitle(value);
    API.post(
      `/posts/update/title`,
      {
        id: props.data._id,
        title: value.trim(),
      },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    )
      .then(result => {
        topictitle.current = value;
        setdisplayvalueTopictitle(value);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
  }
  const cancel = () => {

  }
  const validatefieldUpdate =(value) => {
    if(value.trim().length > 0){
      return true;
    }else{
      return false;
    } 
  }
  React.useEffect(()=>{
    setdisplayvalue(message.current);
    setdisplayvalueTopictitle(topictitle.current);
    replaceDefault(message.current)
  },[displayvalue, displayvalueTopictitle]);

  const replaceDefault = (value) => {
    return <div>
      <div>{value}</div>
      <Button>Editar</Button>
    </div>
  }
  const replaceDefaulttitle = (value) => {
    return <div>
      <div>{value}</div>
      <Button>Editar</Button>
    </div>
  }
  const deleteTopic = () =>{
    setloading(true);
    setvisible(false);
    setvisibletopicmodal(true);
  }
  const deletePost = () => {
    setloading(true);
    setvisibletopicmodal(false);
    setvisible(true);
  }
  const confirmDeletePost =()=>{
    setdisableModalButtonAction(true);
    setdisableModalButtonActionloading(true);
    API.post(
        `/posts/delete`,
        {
          id: props.data._id,
          email: state.user.email,
          deleteReason: deleteReasonText,
          triggeremail: state.user.level,
          location: location,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
        .then(result => {
          setloading(false);
          setvisible(false);
          setvisibletopicmodal(false);
          Modal.info({
            content: 'Publicación eliminada con éxito',
            onOk:(e)=>{window.location.reload()}
          })
        })
        .catch(e => {
          setloading(false);
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
  }
  const confirmDeleteTopic = () => {
      setdisableModalButtonAction(true);
      setdisableModalButtonActionloading(true);
      API.post(
        `/topics/delete`,
        {
          id: props.topicid,
          email: state.user.email,
          deleteReason: deleteReasonText,
          triggeremail: state.user.level,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
        .then(result => {
          setloading(false);
          setvisible(false);
          setvisibletopicmodal(false);
          history.push('/forums');
          Modal.info({
            content: 'Tema eliminado con éxito junto a los comentarios',
          })
        })
        .catch(e => {
          setloading(false);
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
  }
  const handleCancel = () =>{
    setvisible(false);
    setloading(false);
    setvisibletopicmodal(false);
    setdisableModalButtonAction(true);
    setdisableModalButtonActionloading(false);
    setdeleteReasonText();
    setVisiblephotodeletemodal(false)
    setSelectedPhoto(null)
    setVisiblephotoaddmodal(false)
    setFiles([])
    setfileRejectionsError('')
  }
  const onChangereason = e => {
    if(e.target.value.trim().length > 0){
      setdisableModalButtonAction(false);
      setdeleteReasonText(e.target.value);
    }else{
      setdisableModalButtonAction(true);
      setdeleteReasonText();
    }
  };
  const openModal = (image) => {
    setSelectedImage(image);
  };

  // Handle close modal
  const closeModal = () => {
    setSelectedImage(null);
  };
  
  const deleteFile = (pitem,topicid) => {
    setdisableModalButtonAction(false);
    setVisiblephotodeletemodal(true)
    setSelectedPhoto(pitem)
  }
  const confirmDeletePhoto = () => {
    setdisableModalButtonAction(true);
    setdisableModalButtonActionloading(true);
    API.post(
      `/topics/delete/photo`,
      {
        id: props.topicid,
        email: state.user.email,
        keyname: selectedPhoto?.key,
      },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    )
      .then(result => {
        deletePhotoByKey(selectedPhoto?.key)
        setVisiblephotodeletemodal(false)
        setSelectedPhoto(null)
        setdisableModalButtonAction(false);
        setdisableModalButtonActionloading(false);
      })
      .catch(e => {
        setloading(false);
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
  }
  const confirmAddPhoto = () => {
    setdisableModalButtonAction(true);
    setdisableModalButtonActionloading(true);
    API.post(
      `/topics/update/photo`,
      {
        id: props.topicid,
        email: state.user.email,
        photos: files,
      },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    )
      .then(result => {
        addNewPhoto(result.data?.updatedTopic?.photo)
        setVisiblephotoaddmodal(false)
        setFiles([])
        setfileRejectionsError('')
        setdisableModalButtonAction(false);
        setdisableModalButtonActionloading(false);
      })
      .catch(e => {
        setloading(false);
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
  }
  const addNewPhoto = (photos) => {
    setTopicPhotos(photos)
  }
  const deletePhotoByKey = (keyToRemove) => {
    const updatedPhotos = topicPhotos.filter(photo => photo.key !== keyToRemove);
    setTopicPhotos(updatedPhotos);
  };
  const handleDrop = (acceptedFiles, fileRejections) => {
    var filecount = acceptedFiles.length + files.length + topicPhotos.length;
    if(fileRejections.length > 0 || filecount > 4) {
      setfileRejectionsError('You can upload Max 4 photos');
      return;
    }
    setdisableModalButtonAction(false);
    // Function to convert file to base64
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Read the file as a Data URL (base64)
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
  
    // Process each accepted file to include base64Image
    const processFiles = async () => {
      const newFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const base64Image = await convertToBase64(file);
          return {
            name: file.name,
            preview: URL.createObjectURL(file),
            file, // keeping the actual file for potential further use
            base64Image, // adding base64 string
          };
        })
      );
  
      // Update state while keeping previously added files
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };
  
    // Execute the processing of files
    processFiles();
  };
  
  const removeFile =  (file) => {
    const newFiles = files;
    setFiles([]);
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }
  // Function to render the file previews
  const renderFilePreviews = () => {
    return (
      <ul>
        {files.map((file) => (
          <div style={thumb} key={file.name} className="image-preview">
            <div style={thumbInner}>
              <img
                src={file.preview}
                style={imgstyle}
                alt={file.name}
              />
              <Button
                type="primary"
                htmlType="button"
                onClick={() => removeFile(file)}
                className="delete-icon"
              >
                Remove
              </Button>
            </div>
          </div>
        ))}
      </ul>
    );
  };
 if(!state.isAuthenticated){
  return <div></div>
 }else{
    return (
      <div className={"post-wrapper "+(props.positionname)}>
        <div className="post-user">
            <div className="userinfomation">
                <div className="userpic">
                  <Link to={props.data.author?.pathalias?.alias}>
                    <ProfileImage id={props.data?.author?._id}/>
                  </Link>
                </div>
                <div className="username">
                    <div className="title"><h2><Link to={props.data.author?.pathalias?.alias}>{props.data?.author?.firstname} {props.data?.author?.lastname}</Link></h2></div>
                    <div className="category">{props.data.author?.userinfoid?.roles && <Rolename id = {props.data?.author?.userinfoid?.roles}/>}</div>
                </div>
                <div className="createdby">
                    <div className="created">
                    <label>Publicado por:</label>
                    <span className="authername">{props.data?.author?.firstname} {props.data?.author?.lastname}</span>
                    </div>
                    <div className="date">{Moment(props.data.createdAt).format('MMM D, YYYY')}</div>
                    {(state?.user?.level == 1 && props.topictitle)
                    ?
                    <Button onClick={deleteTopic} disabled={loading}>Borrar</Button>
                    :(state?.user?.level == 1) ? <Button onClick={deletePost} disabled={loading}>Borrar</Button>: null}
                </div>
            </div>
        </div>
        <div className="post-message">
        {((state.user.id == props.data.author._id && props.topictitle) || (state.user.level == 1 &&  props.topictitle)) ?
          <div className="title">
            <EasyEdit
                type="text"
                onSave={updateTitle}
                onCancel={cancel}
                saveButtonLabel="Actualizar"
                cancelButtonLabel="Cancelar"
                attributes={{ name: "awesome-input", id: props.data.shortid}}
                value={topictitle.current}
                onHoverCssClass='editmodeon'
                viewAttributes={{ className: "viewmode", id: props.data.shortid}}
                cssClassPrefix='editbox-wrapper-topic'
                displayComponent = {replaceDefaulttitle(topictitle.current)}
                onValidate={validatefieldUpdate}
                validationMessage={<div className="error-message">* Por favor ingrese el título."</div>}
                placeholder="Entra tu pregunta aqui"
                />
          </div>
        : <div className="title">{props.topictitle}</div>}
          
          {((state.user.id == props.data.author._id) || state.user.level == 1) ?
          <div className="post-messageContent edit">
            <EasyEdit
              type="text"
              onSave={save}
              onCancel={cancel}
              saveButtonLabel="Actualizar"
              cancelButtonLabel="Cancelar"
              attributes={{ name: "awesome-input", id: props.data.shortid}}
              value={message.current}
              onHoverCssClass='editmodeon'
              viewAttributes={{ className: "viewmode", id: props.data.shortid}}
              cssClassPrefix='editbox-wrapper'
              displayComponent = {replaceDefault(<Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                </a>
            )}>
              <div dangerouslySetInnerHTML={{ __html: linkifyHtml(message.current?.replace(/\n\r?/g, '<br />'), {target: '_blank'}) }}/>
          </Linkify>)}
              onValidate={validatefieldUpdate}
              validationMessage={<div className="error-message">* Por favor ingrese el detalle de la pregunta.</div>}
              placeholder=""
            />
            </div>
          :<div className="post-messageContent">
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                    </a>
                )}>
                  <div dangerouslySetInnerHTML={{ __html: linkifyHtml(props?.data?.message?.replace(/\n\r?/g, '<br />'), {target: '_blank'}) }}/>
              </Linkify>
            </div>
             }
             {state.user.level == 1 && topicPhotos?.length < 4
             && <Button
             type="primary"
             htmlType="button"
             onClick={() => setVisiblephotoaddmodal(true)}
            >
             Add Photos
           </Button>}
             {topicPhotos?.length > 0 &&
              <div className="photo-wrapper">
                {topicPhotos?.map((pitem, index) => (
                  pitem?.location && <div className="masonry-image">
                    <img
                    key={index}
                    src={pitem?.location}
                    alt="Gallery"
                    onClick={() => openModal(pitem?.location)} // Open modal with clicked image
                  />
                  {state?.user?.level == 1 && <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => deleteFile(pitem,props.topicid)}
                    className="delete-icon"
                  >
                    Remove
                  </Button>
                  }
                  </div>
                ))}
              </div>
              }
              {selectedImage && (
                <div className="modal-overlay" onClick={closeModal}>
                  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <span className="close-button" onClick={closeModal}>&times;</span>
                    <img src={selectedImage} alt="Enlarged" className="enlarged-image" />
                  </div>
                </div>
              )}
          {props.counterbox}
            <Modal
              visible={visible}
              keyboard="false"
              centered="true"
              maskClosable={false}
              closable={false}
              footer={[
                <Button key="cancel" type="secondary" onClick={handleCancel}>
                Cancelar
                </Button>,
                <Button key="submit" type="primary" disabled={disableModalButtonAction} loading={disableModalButtonActionLoading} onClick={confirmDeletePost}>
                Borrar
                </Button>,
              ]}
            >
              <div>¿Estás seguro de que deseas eliminar esta publicación?</div>
              <label><strong>Reason for deletion:<span className="required">*</span></strong></label>
              <TextArea className="reason-d" onChange={onChangereason} value={deleteReasonText}/>
          </Modal>
          <Modal
              visible={visibletopicmodal}
              keyboard="false"
              centered="true"
              maskClosable={false}
              closable={false}
              footer={[
                <Button key="cancel" type="secondary" onClick={handleCancel}>
                Cancelar
                </Button>,
                <Button key="submit" type="primary" disabled={disableModalButtonAction} loading={disableModalButtonActionLoading} onClick={confirmDeleteTopic}>
                Borrar
                </Button>,
              ]}
            >
              <div>¿Está seguro de que desea eliminar estos temas? Esto eliminará también todos los comentarios de la publicacion</div>
              <label><strong>Reason for deletion:<span className="required">*</span></strong></label>
              <TextArea className="reason-d" onChange={onChangereason} value={deleteReasonText}/>       
          </Modal>
          <Modal
              visible={visiblephotodeletemodal}
              keyboard="false"
              centered="true"
              maskClosable={false}
              closable={false}
              footer={[
                <Button key="cancel" type="secondary" onClick={handleCancel}>
                Cancelar
                </Button>,
                <Button key="submit" type="primary" disabled={disableModalButtonAction} loading={disableModalButtonActionLoading} onClick={confirmDeletePhoto}>
                Borrar
                </Button>,
              ]}
            >
              {selectedPhoto?.location && <div className='del-photo-preview'>
                <img src={selectedPhoto?.location}/></div>}
                <h3>Are you sure, you want to delete this photo?</h3>
          </Modal>
          <Modal
          visible={visiblephotoaddmodal}
          keyboard="false"
          centered="true"
          maskClosable={false}
          closable={false}
          footer={[
            <Button key="cancel" type="secondary" onClick={handleCancel}>
            Cancelar
            </Button>,
            <Button key="submit" type="primary" disabled={disableModalButtonAction} loading={disableModalButtonActionLoading} onClick={confirmAddPhoto}>
            Actualizar
            </Button>,
          ]}
          >
            <div className='upload-wrapper'>
              {files.length < 4 && <DropzoneFunctional onDrop={handleDrop}/>}
              <aside>
              {renderFilePreviews()}
              </aside>
              {fileRejectionsError != '' && 
                <p className='error'>{fileRejectionsError}</p>
              }
              <p className='max-msg'>(Max 4 photos)<span className='red'>*</span></p>
            </div>
          </Modal>
        </div>
        <div id={props.data.shortid} />
      </div>
    );
  }
};

export default Post;
